@import "../../styles/_variables";

.hero {
  display: flex;
  flex-direction: column;
}

.heroTopWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.profileImage {
  background-image: url(../../assets/images.nosync/Passfoto.JPG);
  background-position: center;
  background-size: cover;
  height: 200px;
  width: 200px;
  border: 5px solid rgb(43, 43, 43);
  border-radius: 50%;
  transition: 0.4s;
}

.descriptionContainer {
  text-align: center;
}

.descriptionHeadline {
  font-weight: 300;
  font-size: 2.2rem;
}

.description {
  line-height: 1.8;
  margin-bottom: 30px;
}

.socialIconsWrapper {
  width: 100%;
  display: flex;
  justify-content: space-evenly;
}

.socialIcon {
  fill: #dbdbdb;
  transition: 0.2s;
}

.socialIcon:hover {
  fill: rgb(71, 92, 71);
}

.expertise {
  background-color: $darkerTransparent;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  backdrop-filter: blur(8px);
  margin-top: 30px;
  color: #dbdbdb;
}

.tech {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.techStackHeadline {
  font-weight: 300;
  margin-top: 20px;
}

.techIconWrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  min-height: 60px;
  margin: 10px;
}

.techLabel {
  font-size: 13px;
  font-weight: 300;
}

@media (min-width: 1000px) {
  .heroTopWrapper {
    flex-direction: row;
  }

  .descriptionContainer {
    text-align: start;
  }

  .profileImage {
    margin-right: 30px;
    height: 240px;
    width: 240px;
  }

  .socialIconsWrapper {
    justify-content: flex-start;
  }

  .socialIcon {
    margin-right: 30px;
  }

  .techStackHeadline {
    margin: 20px 20px 0px 20px;
  }

  .techIconWrapper {
    margin: 20px;
  }
}
