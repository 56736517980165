@import "../../styles/_mixins";

*,
*:before,
*:after {
  margin: 0;
  padding: 0;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

body {
  background: #f9f9f9;
  font-size: 16px;
}

.projectsContainer {
  @include flexCenter(column);
  list-style: none;
  padding: 0;
  width: 100%;
  height: 100%;
  overflow-y: scroll;
}

.main-title {
  color: #2d2d2d;
  text-align: center;
  text-transform: capitalize;
  padding: 0.7em 0;
}

.container {
  padding: 1em 0;
  float: left;
  width: 50%;
}

.container .title {
  color: #1a1a1a;
  text-align: center;
  margin-bottom: 10px;
}

.content {
  position: relative;
  width: 90%;
  max-width: 400px;
  margin: auto;
  overflow: hidden;
}

.subheaderPflanzy {
  margin-bottom: 10px;
}

.content .content-overlay {
  background: rgba(0, 0, 0, 0.7);
  position: absolute;
  height: 99%;
  width: 100%;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  opacity: 0;
  -webkit-transition: all 0.4s ease-in-out 0s;
  -moz-transition: all 0.4s ease-in-out 0s;
  transition: all 0.4s ease-in-out 0s;
}

.content:hover .content-overlay {
  opacity: 1;
}

.content-image {
  width: 100%;
}

.content-details {
  position: absolute;
  text-align: center;
  padding-left: 1em;
  padding-right: 1em;
  width: 100%;
  top: 50%;
  left: 50%;
  opacity: 0;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  -webkit-transition: all 0.3s ease-in-out 0s;
  -moz-transition: all 0.3s ease-in-out 0s;
  transition: all 0.3s ease-in-out 0s;
}

.content:hover .content-details {
  top: 50%;
  left: 50%;
  opacity: 1;
}

.content-details h3 {
  color: #fff;
  font-weight: 300;
  letter-spacing: 0.15em;
  margin-bottom: 0.5em;
  text-transform: uppercase;
}

.content-details p {
  color: #fff;
  font-size: 0.8em;
}

.fadeIn-bottom {
  top: 80%;
}

.linkContainer {
  @include flexCenter(row);
}

.link {
  margin: 10px;
  fill: white;
  text-decoration: none;
}

@media screen and (max-width: 640px) {
  .container {
    display: block;
    width: 100%;
    padding-top: 0;
  }
}

@media screen and (min-width: 1100px) {
  .container {
    width: 100%;
    display: flex;
    align-items: center;
  }

  .content {
    max-width: 400px;
    margin-right: 20px;
  }

  .projectsContainer {
    flex-direction: row;
    align-items: center;
  }
}
