@import-normalize;
@import "./_variables";
@import "./_mixins";

* {
  box-sizing: border-box;
}

.App {
  height: 100vh;
  font-family: sans-serif;
}

.wrapper {
  background-image: url(../assets/images.nosync/street.jpg);
  background-size: cover;
  background-position: bottom;
  background-repeat: no-repeat;
  height: 100vh;
  display: flex;
  align-items: flex-start;
  padding-top: 10px;
  justify-content: center;
  overflow: hidden;
}

.contentWrapper {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  overflow-y: scroll;
  width: 100%;
  height: 100%;
  border-radius: 10px;
  background-color: $darkerTransparent;
  color: $textColor;
  padding: 30px;
  position: relative;
  backdrop-filter: blur(2px);
}

.main-screen {
  @include flexCenter(column);
  height: 85vh;
  width: 85%;
  position: fixed;
  z-index: 2;
}

.navContainer {
  display: flex;
  justify-content: space-between;
  margin: 0;
  padding: 0;
  width: 100%;
  margin-bottom: 5px;
}

.navListItem {
  list-style-type: none;
  text-align: center;
  padding: 10px 0px;
  width: 50%;
}

.navListItem:first-child {
  margin-right: 10px;
}

.navElement {
  color: $textColor;
  text-decoration: none;
  background-color: $normalTransparent;
  backdrop-filter: blur(6px);
  padding: 5px 0px;
  border-radius: 5px;
  display: block;
  transition: 0.3s;
  &:hover {
    background-color: $highlightTransparent;
  }
}

.currentNav {
  background-color: $highlightTransparent;
}

.particles {
  // overflow: hidden;
}

.transition-top {
  height: 100px;
  width: 100%;
  background-image: linear-gradient(to bottom, black, transparent);
  position: absolute;
  top: 0px;
}

.transition-bottom {
  height: 100px;
  width: 100%;
  background-image: linear-gradient(to bottom, transparent, black);
  position: absolute;
  bottom: 0px;
  z-index: 0;
}

.footer {
  color: $textColor;
  background-color: $lighterTransparent;
  backdrop-filter: blur(6px);
  width: 100%;
  text-align: center;
  margin-top: 10px;
  padding: 5px;
  border-radius: 5px;
  font-size: 12px;
}

@media (min-width: 600px) {
  .wrapper {
    align-items: center;
  }

  .contentWrapper {
    justify-content: flex-start;
    align-items: center;
  }

  .navContainer {
    margin-bottom: 0px;
  }
}

@media (min-width: 1200px) {
  .contentWrapper {
    justify-content: center;
  }

  .main-screen {
    max-height: 650px;
  }
}

@media (min-width: 1360px) {
  .main-screen {
    min-width: 800px;
    max-width: 1100px;
    min-height: 680px;
  }
}
